import React, { useEffect, useState } from 'react';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ethers } from 'ethers';
import FankoFactoryABI from '../../ABI/factory.json';
import FankoTokenABI from '../../ABI/pair.json';
import './chart.css';
import { getFactoryAddress } from '../constant'; // Import the function to get the factory address
import {getCurrencySymbolByChainId} from '../../chains'

const BondingCurveChart = ({ tokenAddress, marketCap, tokenPrice, symbol, creator, imageurl, currentChainId }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: `Token Price (${symbol})`,
        data: [],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false,
      }
    ]
  });

  const factoryAddress = getFactoryAddress(currentChainId); // Get the factory address dynamically based on the chain ID

  Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

  useEffect(() => {
    const fetchData = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, provider);

      try {
        const bondingCurve = await factoryContract.bondingCurves(tokenAddress);
        const spotPrice = ethers.utils.formatEther(bondingCurve.spotPrice);

        setChartData(prevData => ({
          ...prevData,
          labels: [...prevData.labels, new Date().toLocaleTimeString()],
          datasets: [{
            ...prevData.datasets[0],
            data: [...prevData.datasets[0].data, parseFloat(spotPrice)]
          }]
        }));
      } catch (error) {
        console.error("Error fetching bonding curve data:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, [tokenAddress, factoryAddress]);

  const chartOptions = {
    maintainAspectRatio: false, 
    responsive: true,
  };

  const chartStyle = {
    width: '800px', 
    height: '500px', 
  };

  return (
  <div className="chart-container">
    <div className="token-header">
  <div className="token-info">
    <img src={imageurl} alt={`${symbol} Logo`} className="token-logo" />
    <div>
      <h1 className="token-name">{symbol}/ {getCurrencySymbolByChainId(currentChainId)}</h1>
    </div>
  </div>
  <div className="token-stats"> {/* Renamed to token-stats */}
    <div className="info-item">
      <span className="info-title">Market Cap: </span>
      <span className="info-value">${marketCap ? `${(marketCap / 1000).toFixed(2)}K` : 'Loading...'}</span>
    </div>
    <div className="info-item">
  <span className="info-title">CA: </span>
  <a
    href={`https://etherscan.io/address/${tokenAddress}`}
    target="_blank"
    rel="noopener noreferrer"
    className="info-value explorer-link"
  >
    {tokenAddress ? `${tokenAddress.slice(0, 6)}...${tokenAddress.slice(-4)}` : 'Loading...'}
  </a>
</div>
    <div className="info-item">
  <span className="info-title">Created by: </span>
  <a
    href={`https://etherscan.io/address/${creator}`}
    target="_blank"
    rel="noopener noreferrer"
    className="info-value explorer-link"
  >
    {creator ? `${creator.slice(0, 6)}...${creator.slice(-4)}` : 'Loading...'}
  </a>
</div>

    <div className="info-item">
  <span className="info-title">Price: </span>
  <span className="info-value">
    {tokenPrice ? `${ethers.utils.formatEther(tokenPrice)} ${getCurrencySymbolByChainId(currentChainId)}` : 'Loading...'}
  </span>
</div>

  </div>
</div>
  

  <div className="chart">
    <Line data={chartData} options={chartOptions} />
  </div>
</div>
  );
};

export default BondingCurveChart;
