// constants.js

export const NETWORKS = {
    146: {
      name: "morph",
      chainId: 146,
      factoryAddress: "0xf8e8dAF8f0f5D6CF86B3E1BE60307C8c85f230bF",
      multicall: "0x2B4bF7cB5F1527960882a74ed6b999Ec6960aA0B"
    }
  };
  
  // Exporting a function to get the factory address by chainId
  export const getFactoryAddress = (chainId) => {
    return NETWORKS[chainId]?.factoryAddress || "0xDefaultFactoryAddress"; // Return a default or handle missing chainId
  };

  export const getMulticallAddress = (chainId) => {
    return NETWORKS[chainId]?.multicall || "0xDefaultMulticallAddress"; // Provide a fallback
  };
  
  