import { ethers } from "ethers";

export const executeMulticall = async (multicallAddress, calls, provider) => {
  const multicallAbi = [
    "function tryAggregate(bool requireSuccess, (address target, bytes callData)[] calls) public view returns ((bool success, bytes returnData)[] memory)",
  ];
  const multicallContract = new ethers.Contract(multicallAddress, multicallAbi, provider);

  try {
    const results = await multicallContract.tryAggregate(
      true,
      calls.map((call) => ({
        target: call.target,
        callData: new ethers.utils.Interface(call.abi).encodeFunctionData(call.function, call.params),
      }))
    );

    // Decode the results
    return results.map(({ success, returnData }, index) => {
      if (!success) {
        console.error(`Call failed for index ${index}`);
        return null;
      }
      const { abi, function: func } = calls[index];
      return new ethers.utils.Interface(abi).decodeFunctionResult(func, returnData);
    });
  } catch (error) {
    console.error("Error executing multicall:", error);
    throw error;
  }
};
