// ChainSwitcher.js

import React, { useState, useEffect } from 'react';
import { useChain } from '../../context/ChainContext';
import { morph } from '../../chains';
import { useSwitchNetwork, useAccount } from 'wagmi';

import './ChainSwitcher.css';

const networks = { [morph.id]: morph };

const ChainSwitcher = () => {
    const { switchNetwork, isLoading } = useSwitchNetwork();
    const { isConnected } = useAccount(); // Check wallet connection status
  const { selectedChain, setSelectedChain } = useChain(); // Access global state
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    // Set a default chain if none is selected
    if (!selectedChain) {
      setSelectedChain(morph);
    }
  }, [selectedChain, setSelectedChain]);

  const handleSwitch = (chain) => {
    setSelectedChain(chain); // Set selected chain globally
    setDropdownOpen(false);  // Close dropdown
  };

  if (isConnected) return null; // Hide ChainSwitcher if wallet is connected


  return (
    <div className="chain-switcher" onClick={() => setDropdownOpen(!dropdownOpen)}>
      <img src={selectedChain?.iconUrl} alt={`${selectedChain?.name} logo`} className="chain-logo" />
      <span className="chain-name">{selectedChain?.name}</span>
      <span className="dropdown-arrow">▼</span>

      {dropdownOpen && (
        <div className="chain-dropdown">
          {Object.values(networks).map((chain) => (
            <div
              key={chain.id}
              className={`chain-item ${selectedChain?.id === chain.id ? 'selected' : ''}`}
              onClick={() => handleSwitch(chain)}
            >
              <img src={chain.iconUrl} alt={`${chain.name} logo`} />
              <span>{chain.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChainSwitcher;
