import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import {  morph } from "./chains";
import { MyComponent } from './MyComponent';
import TokenPools from './components/getTokenDetails'; 
import TokenDetail from './components/TokenDetail'; 
import { ChainProvider } from './context/ChainContext'; // Import ChainProvider
import './App.css';

const { provider, chains } = configureChains(
  [ morph],
  [
    jsonRpcProvider({
      rpc: chain => ({ http: chain.rpcUrls.default.http[0] }),
    }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export default function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <ChainProvider> {/* Wrap with ChainProvider */}
          <Router>
            <Routes>
              <Route path="/" element={<MyComponent />} /> {/* Render buttons */}
              <Route path="/tokens/:tokenAddress" element={<TokenDetail />} />
            </Routes>
          </Router>
        </ChainProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
