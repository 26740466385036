import React, { useState, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import CreateTokenModal from "./components/CreateTokenButton";
import TokenPools from "./components/getTokenDetails";
import './style.css'; // Link to the CSS file
import Header from "./components/Bonding /Header";
import { useChain } from './context/ChainContext'; // Import ChainContext
import HowItWorksModal from "./components/HowItWorksModal";
import { useNetwork, useAccount } from 'wagmi';

export const MyComponent = () => {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [howItWorksModalIsOpen, setHowItWorksModalIsOpen] = useState(false);
  const { chain } = useNetwork(); // Get current network from wagmi
  const { isConnected } = useAccount(); // Check if wallet is connected
  const { selectedChain } = useChain(); // Get selected chain from context

  // Extract the chain ID to use
  const currentChainId = isConnected && chain?.id ? chain.id : selectedChain?.id;

  // Logging for debugging
  useEffect(() => {
    console.log("Connected Wallet Chain ID:", chain?.id);
    console.log("Selected Chain from Context:", selectedChain);
    console.log("Current Chain ID being passed:", currentChainId);
  }, [chain, selectedChain, isConnected]);

  const openCreateModal = () => setCreateModalIsOpen(true);
  const closeCreateModal = () => setCreateModalIsOpen(false);

  const openHowItWorksModal = () => setHowItWorksModalIsOpen(true);
  const closeHowItWorksModal = () => setHowItWorksModalIsOpen(false);

  return (
    <div className="main-container">
      <Header className="header997" />

      <div className="desc11">
          <button onClick={openCreateModal} className="create-token-btn">[Start a New Coin]</button>
      </div>

      <CreateTokenModal isOpen={createModalIsOpen} onRequestClose={closeCreateModal} />
      {/* <h2>Where Millioners are Made</h2> */}

      <section className="token-pools-section">
        <h2>Deployed Token Pools</h2>
        <TokenPools currentChainId={currentChainId} /> {/* Pass only the chain ID */}
      </section>
    </div>
  );
};

export default MyComponent;
