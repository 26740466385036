import React from 'react';
import './Header.css';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import headerimg from '../../assets/0xso.svg';
import ChainSwitcher from './ChainSwitcher';

const Header = () => {
  return (
    <header className="dapp-header">
      <div className="header-left">
        <img className="imagedesign" src={headerimg} alt="Header Logo" />
      </div>
      
      <div className="header-center">
        <a href="https://x.com/pointswapdotfun" target="_blank" rel="noopener noreferrer">[Twitter]</a>
        <a href="https://t.me/pointswapfun" target="_blank" rel="noopener noreferrer">[Telegram]</a>
        <a href="https://sonic.pointswap.fun/" target="_blank" rel="noopener noreferrer">[Dex]</a>

      </div>

      <div className="header-right">
        <ChainSwitcher />
        <ConnectButton />
      </div>
    </header>
  );
};

export default Header;
