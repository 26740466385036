import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import FankoFactoryABI from "../../ABI/factory.json";
import "./transaction.css";
import { getRpcUrlByChainId } from "../../chains";

const BuySellTransactions = ({ tokenAddress, creationBlock, currentChainId }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const rpcUrl = getRpcUrlByChainId(currentChainId);
        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        const factoryContract = new ethers.Contract(tokenAddress, FankoFactoryABI, provider);
        const currentBlock = await provider.getBlockNumber();
        const maxBlockRange = 10000; // Process 100 blocks per iteration
        let startBlock = Math.max(creationBlock, currentBlock - 2000); // Limit to the last 2000 blocks

        const allEvents = [];

        while (startBlock < currentBlock) {
          const endBlock = Math.min(startBlock + maxBlockRange, currentBlock);

          try {
            // Fetch Buy events
            const buyEvents = await factoryContract.queryFilter(
              factoryContract.filters.Buy(null, null, null, null, null),
              startBlock,
              endBlock
            );

            // Fetch Sell events
            const sellEvents = await factoryContract.queryFilter(
              factoryContract.filters.Sell(null, null, null, null, null),
              startBlock,
              endBlock
            );

            allEvents.push(...buyEvents, ...sellEvents);
          } catch (error) {
            console.error(`Error fetching events in block range ${startBlock} - ${endBlock}:`, error);
          }

          startBlock = endBlock + 1;
        }

        const sortedEvents = allEvents.sort((a, b) => b.blockNumber - a.blockNumber);
        setTransactions(sortedEvents.slice(0, 15)); // Limit to 15 transactions
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    if (tokenAddress && creationBlock) {
      fetchTransactions();
    }

    const intervalId = setInterval(fetchTransactions, 5000);
    return () => clearInterval(intervalId);
  }, [tokenAddress, creationBlock, currentChainId]);

  return (
    <div className="transaction-table">
      <table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Type</th>
            <th>ETH</th>
            <th>Amount</th>
            <th>Transaction</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((tx, index) => (
            <tr key={index}>
              <td>{tx.args.from.slice(0, 6)}...</td>
              <td className={tx.event === "Sell" ? "sell" : "buy"}>{tx.event}</td>
              <td>
                {tx.event === "Sell"
                  ? `${parseFloat(ethers.utils.formatEther(tx.args.amountOut)).toFixed(3)}`
                  : `${parseFloat(ethers.utils.formatEther(tx.args.amountIn)).toFixed(3)}`}
              </td>
              <td>
                {tx.event === "Sell"
                  ? `${parseFloat(ethers.utils.formatEther(tx.args.amountIn)).toFixed(3)}`
                  : `${parseFloat(ethers.utils.formatEther(tx.args.amountOut)).toFixed(3)}`}
              </td>
              <td>
                <a
                  href={`https://bscscan.com/tx/${tx.transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tx.transactionHash.slice(0, 6)}...
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BuySellTransactions;
