import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './TokenPools.css';
import BondingDetails from './Bonding /BondingDetails2';

const TokenCard = ({ token, chainid }) => {
  // Define a default image URL
  const defaultImage = "https://pbs.twimg.com/profile_images/1819068683093696512/mGq9bwZW_400x400.jpg"; // Replace with your preferred default image URL

  return (
    <Link to={`/tokens/${token.address}`}>
  <div className="token-card">
    <div className="token-image-container">
      <img 
        src={token.image || "https://pbs.twimg.com/profile_images/1819068683093696512/mGq9bwZW_400x400.jpg"} // Fallback if token.image is null or undefined
        alt={token.name} 
        className="token-image"
        onError={(e) => {
          e.target.onerror = null; // Prevent infinite loop in case the default image fails
          e.target.src = "https://pbs.twimg.com/profile_images/1819068683093696512/mGq9bwZW_400x400.jpg"; // Default image URL
        }}
      />
    </div>
    <div className="token-details">
      <h3>{token.name} [{token.symbol}]</h3>
      <div className="bonding-details-wrapper">
        <BondingDetails tokenAddress={token.address} currentChainId={chainid} />
      </div>
      <p>
        created by: <a 
          href={`https://etherscan.io/address/${token.creator}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ color: 'yellow' }}
        >
          {token.creator ? `${token.creator.slice(0, 6)}...${token.creator.slice(-4)}` : "N/A"}
        </a>
      </p>
    </div>
  </div>
</Link>

  );
};

export default TokenCard;
