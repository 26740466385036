import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import FankoTokenABI from "../../ABI/pair.json";
import './topholder.css'

const TokenHolders = ({ tokenAddress, creationBlock }) => {
  const [holders, setHolders] = useState([]);
  const [totalSupply, setTotalSupply] = useState(0);

  useEffect(() => {
    const fetchHolders = async () => {
      try {
        console.log('Fetching holders for token:', tokenAddress, 'from block:', creationBlock);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const tokenContract = new ethers.Contract(tokenAddress, FankoTokenABI, provider);

        // Fetch total supply
        const supply = await tokenContract.totalSupply();
        setTotalSupply(parseFloat(ethers.utils.formatEther(supply)));

        // Listen to Transfer events from the creation block
        const transferEvents = await tokenContract.queryFilter(
          tokenContract.filters.Transfer(null, null),
          creationBlock,
          'latest'
        );

        console.log('Transfer events fetched:', transferEvents);

        const balances = {};
        transferEvents.forEach((event) => {
          const from = event.args.from;
          const to = event.args.to;
          const amount = ethers.utils.formatEther(event.args.value);

          // Update balances for 'from' address
          if (from !== ethers.constants.AddressZero) {
            balances[from] = (balances[from] || 0) - parseFloat(amount);
          }

          // Update balances for 'to' address
          balances[to] = (balances[to] || 0) + parseFloat(amount);
        });

        console.log('Balances:', balances);

        // Convert the balances object to an array and sort by balance
        const sortedHolders = Object.entries(balances)
          .filter(([address, balance]) => balance > 0) // Ensure only positive balances are included
          .sort(([, balanceA], [, balanceB]) => balanceB - balanceA)
          .slice(0, 10);

        setHolders(sortedHolders);
      } catch (error) {
        console.error("Error fetching holders:", error);
      }
    };

    if (tokenAddress && creationBlock) {
      fetchHolders();
    }
  }, [tokenAddress, creationBlock]);

  return (
    <div className="top-holders-container">
  {/* <h3>Top 10 Token Holders</h3> */}
  <table className="holders-table">
    <thead>
      <tr>
        <th>Holder Distribution</th>
        {/* <th>Address</th>
        <th>Percentage</th> */}
      </tr>
    </thead>
    <tbody>
      {holders.length > 0 ? (
        holders.map(([address, balance], index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{address.slice(0, 9)}</td>
            <td>{((balance / totalSupply) * 100).toFixed(2)}%</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="3">No holders found.</td>
        </tr>
      )}
    </tbody>
  </table>
</div>
  );
};

export default TokenHolders;
