const morph = {
    id: 146,
    name: 'Sonic',
    network: 'morph-l2',
    iconUrl: 'https://pbs.twimg.com/profile_images/1819068683093696512/mGq9bwZW_400x400.jpg',
    iconBackground: '#f0b90b',
    nativeCurrency: {
        decimals: 18,
        name: 'Sonic Coin',
        symbol: 'S',
    },
    rpcUrls: {
        default: {
            http: ['https://rpc.soniclabs.com'],
        },
    },
    blockExplorers: {},
    testnet: true,
    averageBlockTimeSeconds: 12,
    priceUSD: 2,
};


const networks = {
    [morph.id]: morph
};

// Export a function to get the RPC URL based on chainId
export const getRpcUrlByChainId = (chainId) => {
    return networks[chainId]?.rpcUrls.default.http[0] || 'https://rpc.soniclabs.com';
};

// Get the currency symbol based on chainId
export const getCurrencySymbolByChainId = (chainId) => {
    return networks[chainId]?.nativeCurrency?.symbol || 'N/A';
};

// Get chain-specific data (block time and USD price) by chainId
export const getChainDataById = (chainId) => {
    return networks[chainId] || {};
};

export {networks,morph};
