import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import FankoTokenABI from '../../ABI/pair.json'; 
import FankoFactoryABI from '../../ABI/factory.json'; 
import './BondingDetails.css'; 
import { getRpcUrlByChainId,getCurrencySymbolByChainId } from '../../chains'; // Import the correct function from chains
import { getFactoryAddress } from '../constant'; // Import from constants.js

const BondingDetails = ({ tokenAddress, currentChainId }) => {
  const [tokenName, setTokenName] = useState('');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [bondingInfo, setBondingInfo] = useState({ ethReal: 0, totalEth: 0 });
  // const [currencySymbol, setCurrencySymbol] = useState('');


  useEffect(() => {
    const fetchBondingDetails = async () => {
      try {
        // Get the RPC URL and factory address based on the connected chain ID
        const rpcUrl = getRpcUrlByChainId(currentChainId);
        const factoryAddress = getFactoryAddress(currentChainId); // Get factory address from constants.js

        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

        const tokenContract = new ethers.Contract(tokenAddress, FankoTokenABI, provider);
        const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, provider);

        // Fetch token name
        const name = await tokenContract.name();

        // Fetch bonding curve details (ethRealReserve and totalEth) from the factory
        const bondingCurve = await factoryContract.bondingCurves(tokenAddress);
        const ethReal = parseFloat(ethers.utils.formatEther(bondingCurve.ethRealReserve)).toFixed(2); 
        const totalEth = parseFloat(ethers.utils.formatEther(bondingCurve.ethRealReserveThreshold)).toFixed(2); 

        setBondingInfo({ ethReal, totalEth });
        setTokenName(name);

        // If the wallet is connected, fetch the balance
        if (window.ethereum) {
          const signer = provider.getSigner(); 
          const userAddress = await signer.getAddress();
          const balance = await tokenContract.balanceOf(userAddress);
          setTokenBalance(parseFloat(ethers.utils.formatEther(balance)).toFixed(3)); 
        }

      } catch (error) {
        console.error("Error fetching bonding details:", error);
      }
    };

    if (currentChainId) {
      fetchBondingDetails();
    }
  }, [tokenAddress, currentChainId]);

  // Calculate the bonding curve progress
  const bondingProgress = (bondingInfo.ethReal / bondingInfo.totalEth) * 100;

  return (
    <div className="bonding-details">
      <p style={{color:"white"}}>Bonding curve progress: {bondingInfo.ethReal}/{bondingInfo.totalEth} {getCurrencySymbolByChainId(currentChainId)} ({bondingProgress.toFixed(2)}%)</p>
      <div className="bonding-progress">
        <div className="progress-bar" style={{ width: `${bondingProgress}%` }}></div>
      </div>
    </div>
  );
};

export default BondingDetails;
