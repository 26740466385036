import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import FankoFactoryABI from "../ABI/factory.json";
import FankoTokenABI from "../ABI/pair.json";
import TokenCard from "./TokenCard";
import "./gettokendetails.css";
import { getFactoryAddress } from "./constant";
import { getRpcUrlByChainId } from "../chains";
import { executeMulticall } from "../utils/callhelper"; // Multicall utility function

const MULTICALL_ADDRESS = "0x5952F3a8127F49c1342d2BAd16FE58f399a50DC0"; // Replace with your deployed Multicall2 address

const TokenPools = ({ currentChainId }) => {
  const [tokenDetails, setTokenDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const factoryAddress = getFactoryAddress(currentChainId); // Get factory address based on chain ID

  const fetchTokenPools = async () => {
    if (!factoryAddress) {
      console.error("Factory address not found for the current chain.");
      return;
    }
  
    try {
      setIsLoading(true);
      const provider = new ethers.providers.JsonRpcProvider(getRpcUrlByChainId(currentChainId));
      const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, provider);
  
      // Fetch all token addresses from the factory contract
      const allTokens = await factoryContract.getAllTokens();
  
      console.log("All Tokens:", allTokens);
  
      // Prepare Multicall calls for each token's details
      const calls = allTokens.flatMap((tokenAddress) => [
        {
          target: factoryAddress,
          abi: FankoFactoryABI,
          function: "getTokenDetailsByAddress",
          params: [tokenAddress],
        },
        {
          target: tokenAddress,
          abi: FankoTokenABI,
          function: "name",
          params: [],
        },
        {
          target: tokenAddress,
          abi: FankoTokenABI,
          function: "symbol",
          params: [],
        },
      ]);
  
      console.log("Prepared Calls for Multicall:", calls);
  
      // Execute the Multicall
      const results = await executeMulticall(MULTICALL_ADDRESS, calls, provider);
  
      console.log("Multicall Results:", results);
  
      // Parse Multicall results
      const parsedData = allTokens.map((tokenAddress, index) => {
        try {
          // Decode getTokenDetailsByAddress result
          const tokenDetails = results[index * 3][0]; // Access the tuple directly
  
          if (!tokenDetails || tokenDetails.length < 7) {
            console.error(`No data for token at index ${index}`);
            return null;
          }
  
          const parsedTokenDetails = {
            creator: tokenDetails[0],
            creationBlock: tokenDetails[1].toString(),
            description: tokenDetails[2],
            image: tokenDetails[3] , // Default image link
            twitter: tokenDetails[4],
            telegram: tokenDetails[5],
            website: tokenDetails[6],
          };
  
          // Decode name and symbol
          const name = results[index * 3 + 1][0]; // Unwrap single-element array
          const symbol = results[index * 3 + 2][0]; // Unwrap single-element array
  
          return {
            address: tokenAddress,
            ...parsedTokenDetails,
            name,
            symbol,
          };
        } catch (error) {
          console.error(`Error decoding token at index ${index}:`, error);
          return null;
        }
      }).filter(Boolean); // Remove null values
  
      console.log("Parsed Token Details:", parsedData);
  
      setTokenDetails(parsedData);
    } catch (error) {
      console.error("Error fetching token pools:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    if (currentChainId) {
      fetchTokenPools(); // Fetch pools on initial load
      
    }
  }, [currentChainId]);

  return (
    <div className="token-pools">
      <div className="token-pools-grid">
        {isLoading ? (
          <p className="Loading88">Loading...</p>
        ) : tokenDetails.length > 0 ? (
          tokenDetails.map((token, index) => (
            <TokenCard key={index} token={token} chainid={currentChainId} />
          ))
        ) : (
          <p className="Loading88">No token pools available.</p>
        )}
      </div>
    </div>
  );
};

export default TokenPools;
