import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Modal from 'react-modal';
import { useSigner, useNetwork } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import './CreateTokenModal.css';
import FankoFactoryABI from "../ABI/factory.json"; 
import { getFactoryAddress } from './constant';
import { getCurrencySymbolByChainId } from '../chains';

const CreateTokenModal = ({ isOpen, onRequestClose }) => {
  const { data: signer } = useSigner();
  const { chain } = useNetwork();
  const [factoryAddress, setFactoryAddress] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [description, setDescription] = useState('');
  const [ethAmount, setEthAmount] = useState('0');
  const [tokenCreationFee, setTokenCreationFee] = useState(null);
  const [tokensBought, setTokensBought] = useState(0);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [imageUri, setImageUri] = useState('');
  const [twitterUri, setTwitterUri] = useState('');
  const [telegramUri, setTelegramUri] = useState('');
  const [websiteUri, setWebsiteUri] = useState('');

  useEffect(() => {
    if (chain) {
      const currentFactoryAddress = getFactoryAddress(chain.id);
      setFactoryAddress(currentFactoryAddress);
    }
  }, [chain]);

  useEffect(() => {
    const fetchTokenCreationFee = async () => {
      try {
        if (factoryAddress && signer) {
          const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, signer);
          const fee = await factoryContract.tokenCreationFee();
          setTokenCreationFee(ethers.utils.formatEther(fee));
        }
      } catch (error) {
        console.error("Error fetching token creation fee:", error);
      }
    };

    fetchTokenCreationFee();
  }, [factoryAddress, signer]);

  useEffect(() => {
    if (tokenCreationFee) {
      const tokenAmount = (ethAmount - tokenCreationFee) > 0 ? ethAmount - tokenCreationFee : 0;
      setTokensBought(tokenAmount);
    }
  }, [ethAmount, tokenCreationFee]);

  const handleCreateToken = async () => {
    if (!signer) {
      alert("Please connect your wallet first!");
      return;
    }

    setLoading(true);

    const ethToSend = ethAmount && parseFloat(ethAmount) >= parseFloat(tokenCreationFee) ? ethAmount : tokenCreationFee;

    try {
      const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, signer);

      const tx = await factoryContract.createToken(
        tokenName,
        tokenSymbol,
        description,
        [imageUri, twitterUri, telegramUri, websiteUri],
        {
          value: ethers.utils.parseEther(ethToSend),
        }
      );

      const receipt = await tx.wait();
      const event = receipt.events.find(event => event.event === "NewToken");
      const newTokenAddress = event.args[1];

      onRequestClose();
      navigate(`/tokens/${newTokenAddress}`);
    } catch (error) {
      console.error("Error creating token:", error);
      alert("Token creation failed!");
    } finally {
      setLoading(false);
    }
  };

  const openSecondModal = () => setShowSecondModal(true);
  const goBackToFirstModal = () => setShowSecondModal(false);

  return (
    <>
      {!showSecondModal && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          className="token-modal"
          overlayClassName="token-modal-overlay"
        >
          <div className="token-modal-header">
            <h2>Create your token</h2>
            <button className="close-button" onClick={onRequestClose}>X</button>
          </div>
          <div className="token-modal-body">
            <label>Token Name</label>
            <input
              type="text"
              placeholder="Enter token name"
              value={tokenName}
              onChange={(e) => setTokenName(e.target.value)}
            />
            <label>Symbol</label>
            <input
              type="text"
              placeholder="Enter token symbol"
              value={tokenSymbol}
              onChange={(e) => setTokenSymbol(e.target.value)}
            />
            <label>Description</label>
            <textarea
              placeholder="Describe your token"
              maxLength={240}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="seed-token-section">
              <label>Seed your token <span>(optional)</span></label>
              <p>The value should be more than {tokenCreationFee || '...'} {getCurrencySymbolByChainId(chain?.id)} to cover the token creation fee.</p>
              <input
                type="number"
                placeholder={`0 ${getCurrencySymbolByChainId(chain?.id)}`}
                value={ethAmount}
                onChange={(e) => setEthAmount(e.target.value)}
              />
              <p>You'll get {tokensBought} tokens [{((tokensBought / ethAmount) * 100).toFixed(2) || 0}% of total supply]</p>
            </div>
            <div className="summary-section">
              <div className="summary-item">
                <span>Seed token bought</span>
                <span>{tokensBought} {getCurrencySymbolByChainId(chain?.id)}</span>
              </div>
              <div className="summary-item">
                <span>Token creation fee</span>
                <span>{tokenCreationFee || '...'} {getCurrencySymbolByChainId(chain?.id)}</span>
              </div>
              <div className="summary-item">
                <span>Total spend</span>
                <span>{ethAmount} {getCurrencySymbolByChainId(chain?.id)}</span>
              </div>
            </div>
            <button className="continue-button" onClick={openSecondModal}>
              Continue
            </button>
          </div>
        </Modal>
      )}

      {showSecondModal && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          className="token-modal"
          overlayClassName="token-modal-overlay"
        >
          <div className="token-modal-header">
            <h2>Additional Information</h2>
            <button className="close-button" onClick={onRequestClose}>X</button>
          </div>
          <div className="token-modal-body">
            <label>Image URI</label>
            <input
              type="text"
              placeholder="Enter image URI"
              value={imageUri}
              onChange={(e) => setImageUri(e.target.value)}
            />
            <label>Twitter URI</label>
            <input
              type="text"
              placeholder="Enter Twitter URI"
              value={twitterUri}
              onChange={(e) => setTwitterUri(e.target.value)}
            />
            <label>Telegram URI</label>
            <input
              type="text"
              placeholder="Enter Telegram URI"
              value={telegramUri}
              onChange={(e) => setTelegramUri(e.target.value)}
            />
            <label>Website URI (Optional)</label>
            <input
              type="text"
              placeholder="Enter Website URI"
              value={websiteUri}
              onChange={(e) => setWebsiteUri(e.target.value)}
            />
            
            <div className="summary-section">
              <div className="summary-item">
                <span>Seed token bought</span>
                <span>{tokensBought} ETH</span>
              </div>
              <div className="summary-item">
                <span>Token creation fee</span>
                <span>{tokenCreationFee} ETH</span>
              </div>
              <div className="summary-item">
                <span>Total spend</span>
                <span>{ethAmount} ETH</span>
              </div>
            </div>
            <button className="back-button" onClick={goBackToFirstModal}>
              Back
            </button>
            <button className="create-token-button" onClick={handleCreateToken} disabled={loading}>
              {loading ? "Processing..." : "Create Token"}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CreateTokenModal;