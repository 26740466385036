// src/context/ChainContext.js

import React, { createContext, useContext, useState } from 'react';

// Create the context
const ChainContext = createContext();

// Custom hook to use the ChainContext
export const useChain = () => {
  return useContext(ChainContext);
};

// ChainProvider component to wrap around the app
export const ChainProvider = ({ children }) => {
  const [selectedChain, setSelectedChain] = useState(null);

  return (
    <ChainContext.Provider value={{ selectedChain, setSelectedChain }}>
      {children}
    </ChainContext.Provider>
  );
};
