import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import FankoFactoryABI from "../../ABI/factory.json";
import FankoTokenV2 from '../../ABI/pair.json'; // Ensure the ABI is correct
import './TradeInterface.css';
import Notification from './Notification'; // Import Notification component
import { getFactoryAddress } from '../constant'; // Import factory address function from constants
import { getCurrencySymbolByChainId } from '../../chains';

const BuySellInterface = ({ tokenAddress, currentChainId }) => {
  const [ethAmount, setEthAmount] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');
  const [isBuying, setIsBuying] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tokenSymbol, setTokenSymbol] = useState('');

  const [minTokens, setMinTokens] = useState('');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [ethBalance, setEthBalance] = useState('0');
  const [minETH, setMinETH] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' }); // Notification state

  const factoryAddress = getFactoryAddress(currentChainId); // Fetch the factory address based on the chain ID

  const handleReset = () => {
    if (isBuying) {
      setEthAmount('');
      setMinTokens('');
    } else {
      setTokenAmount('');
      setMinETH('');
    }
  };

  useEffect(() => {
    const fetchBalances = async () => {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(tokenAddress, FankoTokenV2, signer);
        const userAddress = await signer.getAddress();

        // Fetch ETH balance
        const ethBal = await provider.getBalance(userAddress);
        setEthBalance(parseFloat(ethers.utils.formatEther(ethBal)).toFixed(3));

        // Fetch token balance
        const tokenBal = await tokenContract.balanceOf(userAddress);
        setTokenBalance(parseFloat(ethers.utils.formatEther(tokenBal)).toFixed(3));

        // Fetch token symbol
        const symbol = await tokenContract.symbol();
        setTokenSymbol(symbol);
      } catch (error) {
        console.error("Error fetching balances:", error);
      }
    };

    if (window.ethereum) {
      fetchBalances();
    }
  }, [isBuying, tokenAddress]);

  const fetchMinTokens = async (ethValue) => {
    if (ethValue) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, provider);

      try {
        const minTokensOut = await factoryContract.getTokenAmountOut(tokenAddress, ethers.utils.parseEther(ethValue));
        setMinTokens(ethers.utils.formatUnits(minTokensOut.toString(), 18));
      } catch (error) {
        console.error("Error fetching minimum tokens:", error);
      }
    }
  };

  const fetchMinETH = async (tokenValue) => {
    if (tokenValue) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, provider);

      try {
        const minEthOut = await factoryContract.getEthAmountOut(tokenAddress, ethers.utils.parseEther(tokenValue));
        setMinETH(ethers.utils.formatEther(minEthOut.toString()));
      } catch (error) {
        console.error("Error fetching minimum ETH:", error);
      }
    }
  };

  useEffect(() => {
    if (isBuying) {
      fetchMinTokens(ethAmount);
    } else {
      fetchMinETH(tokenAmount);
    }
  }, [ethAmount, tokenAmount, isBuying]);

  const handleBuy = async () => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, signer);
      const recipient = await signer.getAddress();

      const tx = await factoryContract.buy(tokenAddress, 0, recipient, {
        value: ethers.utils.parseEther(ethAmount),
      });
      await tx.wait();
      setNotification({ message: 'Buy successful!', type: 'success' });
    } catch (error) {
      console.error("Buy Error:", error);
      setNotification({ message: 'Buy failed!', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSell = async () => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(tokenAddress, FankoTokenV2, signer);

      // Approve the factory contract to spend tokens
      const approvalTx = await tokenContract.approve(factoryAddress, ethers.utils.parseEther(tokenAmount));
      await approvalTx.wait(); // Wait for approval to complete

      const factoryContract = new ethers.Contract(factoryAddress, FankoFactoryABI, signer);
      const recipient = await signer.getAddress();

      // Proceed with the sell transaction
      const tx = await factoryContract.sell(tokenAddress, ethers.utils.parseEther(tokenAmount), 0, recipient);
      await tx.wait();
      setNotification({ message: 'Sell successful!', type: 'success' });
    } catch (error) {
      console.error("Sell Error:", error);
      setNotification({ message: 'Sell failed!', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="buy-sell-interface">
      <div className="switch-buttons">
        <button onClick={() => setIsBuying(true)} className={isBuying ? 'active' : ''}>Buy</button>
        <button onClick={() => setIsBuying(false)} className={!isBuying ? 'active' : ''}>Sell</button>
      </div>

      <div className={isBuying ? "buy-section" : "sell-section"}>
        <div className="input-container">
          <input
            type="number"
            value={isBuying ? ethAmount : tokenAmount}
            onChange={(e) => isBuying ? setEthAmount(e.target.value) : setTokenAmount(e.target.value)}
            placeholder={isBuying ? "Enter S Amount" : "Enter Token Amount"}
          />
          <span className="token-symbol">
            {isBuying ? 'S' : tokenSymbol || 'Tokens'}
          </span>
        </div>

        <div className="percentage-buttons">
          {isBuying ? (
            <>
              <button onClick={handleReset} className="reset-button">reset</button>
              <button onClick={() => setEthAmount("0.1")}>0.1 {getCurrencySymbolByChainId(currentChainId)}</button>
              <button onClick={() => setEthAmount("0.5")}>0.5 {getCurrencySymbolByChainId(currentChainId)}</button>
              <button onClick={() => setEthAmount("1")}>1 {getCurrencySymbolByChainId(currentChainId)}</button>
              <button onClick={() => setEthAmount(ethBalance)}>Max</button>
            </>
          ) : (
            <>
              Balance: {tokenBalance}
              <button onClick={() => setTokenAmount(tokenBalance)}>Max</button>
            </>
          )}
        </div>

        {isBuying && ethAmount ? (
          <p>Receive {minTokens ? parseFloat(minTokens).toFixed(3) : 'Loading...'} Tokens</p>
        ) : !isBuying && tokenAmount ? (
          <p>Receive {minETH ? parseFloat(minETH).toFixed(3) : 'Loading...'} {getCurrencySymbolByChainId(currentChainId)}</p>
        ) : null}

        <button onClick={isBuying ? handleBuy : handleSell} disabled={loading} className="place-trade-button">
          {loading ? 'Processing...' : 'Place Trade'}
        </button>
      </div>

      {notification.message && (
        <Notification message={notification.message} type={notification.type} onClose={() => setNotification({ message: '', type: '' })} />
      )}
    </div>
  );
};

export default BuySellInterface;
